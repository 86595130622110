import { createReducer } from 'redux-act'
import State, { NotificationMessageState } from './types'
import { disposeNotification, clearNotification } from './actions'
import initialState from './state'

const _disposeNotification = (state: State, message: NotificationMessageState): State => ({
    ...state,
    messages:
        state.messages.length > 3
            ? [...state.messages.slice(1), { id: crypto?.randomUUID(), ...message }]
            : [...state.messages, { id: crypto?.randomUUID(), ...message }],
})

const _clearNotification = (state: State, id: string): State => ({
    ...state,
    messages: state.messages.filter(message => message.id !== id),
})

const reducer = createReducer<State>(on => {
    on(disposeNotification, _disposeNotification)
    on(clearNotification, _clearNotification)
}, initialState)

export default reducer
