import getConfig from 'next/config'

import { nodeEnvironment, dev } from '@common/utils/environment-utils'
import { isServer } from '@common/utils/platform-utils'

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig()
const {
    BASE_PATH,
    BASE_URL,
    CLASSIC_BASE_URL,
    ACCOUNTS_GOOGLE_LOGIN_URL,
    ENV,
    FIREBASE_API_KEY,
    FIREBASE_APP_ID,
    FIREBASE_MESSAGING_SENDER_ID,
    GTM_CONTAINER_ID,
    SENTRY_DSN,
    BUILD_ID,
} = publicRuntimeConfig

const { BFF_BASE_URL, SENTRY_TOKEN, SENTRY_RELEASE } = serverRuntimeConfig

interface AppConfig {
    basePath: string
    baseUrl: string
    bffBaseUrl?: string
    classicBaseUrl: string
    accountsGoogleLoginUrl: string
    dev: boolean
    environment: 'development' | 'staging' | 'production'
    firebaseApiKey?: string
    firebaseAppId?: string
    firebaseMessagingSenderId?: string
    gtmContainerId: string
    nodeEnvironment: 'development' | 'production'
    sentryDSN: string
    /** Sentry Release is defined on client side.
     * On server side, it's not defined here, but rather provided as app's build ID. */
    sentryRelease?: string
    sentryToken?: string
    buildId: string
}

const config: AppConfig = {
    basePath: BASE_PATH,
    baseUrl: BASE_URL,
    bffBaseUrl: BFF_BASE_URL,
    classicBaseUrl: ENV === 'production' && !isServer ? location.origin : CLASSIC_BASE_URL,
    accountsGoogleLoginUrl: ACCOUNTS_GOOGLE_LOGIN_URL,
    dev,
    environment: ENV,
    firebaseApiKey: FIREBASE_API_KEY,
    firebaseAppId: FIREBASE_APP_ID,
    firebaseMessagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    gtmContainerId: GTM_CONTAINER_ID,
    nodeEnvironment,
    sentryDSN: SENTRY_DSN,
    sentryRelease: SENTRY_RELEASE,
    sentryToken: SENTRY_TOKEN,
    buildId: BUILD_ID,
}

export default config
