import { NetworkService } from '@common/services'
import { Headers } from '@common/services/network-service/types'
import BaseTransport from '@common/transports/common/base-transport'
import { RequestUserConfig } from '@common/types'
import { isServer } from '@common/utils/platform-utils'
import config from '@common/config'
import CheckDeviceSessionTransport from '@common/transports/check-device-session-transport'
import ValidateEmailTransport from '@common/transports/common/validate-email-transport'
import {
    RegistrationRequestBodyTransport,
    RegistrationResponseTransport,
} from '@common/transports/ho-registration-form-transports'

class UserClient {
    private readonly _networkService: NetworkService
    private readonly _basePath: string

    constructor(baseUri: string, basePath: string) {
        this._networkService = new NetworkService(baseUri)
        this._basePath = basePath
    }

    public async setup(requestUserConfig?: RequestUserConfig) {
        if (requestUserConfig) {
            const headers: Headers = {
                'x-language': requestUserConfig.language,
                'x-domain': requestUserConfig.domain,
            }

            this._networkService.setDefaultHeaders(headers)
        }
    }

    public checkDeviceSession(): Promise<BaseTransport<CheckDeviceSessionTransport>> {
        return this._networkService.get({
            url: `${this._basePath}/device-session`,
        })
    }

    public validateEmail({
        email,
        hashedEmail,
    }: {
        email: string
        hashedEmail?: string
    }): Promise<BaseTransport<ValidateEmailTransport>> {
        return this._networkService.post({
            body: { email, ...(!!hashedEmail && { hashed_token: hashedEmail }) },
            url: `${this._basePath}/emails/validate`,
        })
    }

    public register({
        registrationRequestBody,
    }: {
        registrationRequestBody: RegistrationRequestBodyTransport
    }): Promise<RegistrationResponseTransport> {
        return this._networkService.post({
            body: registrationRequestBody,
            url: `${this._basePath}/register`,
        })
    }
}

const baseUri = isServer ? (config.bffBaseUrl as string) : location.origin + config.basePath

export default new UserClient(baseUri, '/api/v1/users')
