import { createContext } from 'react'

import { Locale, Messages } from '../i18n/types'
import { defaultLocale, defaultMessages } from '../i18n/config'

export interface LocaleContextValue {
    readonly locale: Locale
    readonly changeLocale: (locale: string) => void
    readonly messages: Messages
}

export const LocaleContext = createContext<LocaleContextValue>({
    locale: defaultLocale,
    changeLocale: () => null,
    messages: defaultMessages,
})

export const LocaleContextProvider = LocaleContext.Provider
export const LocaleContextConsumer = LocaleContext.Consumer
