import { createAction } from 'redux-act'

import {
    START_CHECK_DEVICE_SESSION_TRANSPORT,
    SAVE_CHECK_DEVICE_SESSION_TRANSPORT,
    ERROR_CHECK_DEVICE_SESSION_TRANSPORT,
} from './action-types'
import { Thunk } from '../types'
import { CallbackFunction } from '@common/types'
import { isFunction } from '@common/utils/type-utils'
import BaseTransport from '@common/transports/common/base-transport'
import CheckDeviceSessionTransport from '@common/transports/check-device-session-transport'
import { userClient } from '@common/clients'

export const startCheckDeviceSessionTransport = createAction(START_CHECK_DEVICE_SESSION_TRANSPORT)
export const saveCheckDeviceSessionTransport = createAction<BaseTransport<CheckDeviceSessionTransport>>(
    SAVE_CHECK_DEVICE_SESSION_TRANSPORT,
)
export const errorCheckDeviceSessionTransport = createAction<Error>(ERROR_CHECK_DEVICE_SESSION_TRANSPORT)
export const getCheckDeviceSessionTransport = ({
    successCallback,
    errorCallback,
}: {
    successCallback?: CallbackFunction
    errorCallback?: CallbackFunction
} = {}): Thunk => async dispatch => {
    dispatch(startCheckDeviceSessionTransport())
    try {
        const response = await userClient.checkDeviceSession()

        dispatch(saveCheckDeviceSessionTransport(response))
        if (isFunction(successCallback)) {
            return successCallback(response)
        }
    } catch (error) {
        dispatch(errorCheckDeviceSessionTransport(error))
        if (isFunction(errorCallback)) {
            return errorCallback(error)
        }
    }
}
