export const DEVICE_SESSION_ID_HEADER = 'device-id'
export const SENTRY_TOKEN_HEADER = 'x-sentry-token'
export const PREFERRED_LOCALE_COOKIE = 'preferred_locale'
export const HERO_SECTION_MAIN_TEXT = 'MainText'
export const HERO_SECTION_SUBTEXT = 'SubText'
interface BenefitsSectionItem {
    key: string
    modal: boolean
}

export const BENEFITS_SECTION_ITEMS: BenefitsSectionItem[] = [
    {
        key: 'guestBenefit',
        modal: false,
    },
    {
        key: 'fairnessBenefit',
        modal: true,
    },
    {
        key: 'refundBenefit',
        modal: true,
    },
    {
        key: 'supportBenefit',
        modal: false,
    },
    {
        key: 'successBenefit',
        modal: false,
    },
    {
        key: 'adsBenefit',
        modal: false,
    },
]

interface HeroSectionItem {
    key: string
}

export const HERO_SECTION_ITEMS: HeroSectionItem[] = [
    {
        key: 'booking',
    },
    {
        key: 'commission',
    },
    {
        key: 'moneyBack',
    },
]

interface MultilingualWebsiteItem {
    key: string
}

export const MULTILINGUAL_WEBSITE_ITEMS: MultilingualWebsiteItem[] = [
    {
        key: 'internationMLWBenefit',
    },
    {
        key: 'profitMLWBenefit',
    },
    {
        key: 'multilingualMLWBenefit',
    },
    {
        key: 'visitorMLWBenefit',
    },
]

interface MultilingualWebsiteCountry {
    title: string
}

export const MULTILINGUAL_WEBSITE_COUNTRIES: MultilingualWebsiteCountry[] = [
    {
        title: 'France',
    },
    {
        title: 'Netherland',
    },
    {
        title: 'Italy',
    },
    {
        title: 'Spain',
    },
]

interface PremiumPartnershipItem {
    key: string
}

export const PREMIUM_PARTNERSHIP_ITEMS: PremiumPartnershipItem[] = [
    {
        key: 'advertisePartnershipItem',
    },
    {
        key: 'carePartnershipItem',
    },
    {
        key: 'flexibilityPartnershipItem',
    },
]

export const UTMKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term']
