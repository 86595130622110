import React, { FC } from 'react'

import { getLocaleFromLanguage } from '../i18n/utils'
import LocaleProvider from '../i18n/provider'
import { RequestUserConfigContextProvider } from '../context/request-user-config'
import { RequestUserConfig } from '@common/types'
import { Messages } from '../i18n/types'

interface Props {
    requestUserConfig: RequestUserConfig
    messages: Messages
}

const UserSettingsConfigProvider: FC<Props> = ({ requestUserConfig, messages, children }) => {
    return (
        <RequestUserConfigContextProvider value={requestUserConfig}>
            <LocaleProvider
                initialLocale={getLocaleFromLanguage(requestUserConfig.language)}
                initialMessages={messages}
            >
                {children}
            </LocaleProvider>
        </RequestUserConfigContextProvider>
    )
}

export default UserSettingsConfigProvider
