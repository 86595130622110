import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { AppProps } from 'next/app'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles, queries, ScreenProvider, theme } from 'react-components'
import { Provider } from 'react-redux'
import { polyfill } from 'smoothscroll-polyfill'
import { homePageEvents } from '../common/event'
import { UTMKeys } from '@server/constants'
import 'smoothscroll-anchor-polyfill'

import 'normalize.css'
import 'pure-react-carousel/dist/react-carousel.es.css'

import { isServer } from '@common/utils/platform-utils'
import { GlobalFontFaces } from '@src/common/fonts'
import { useStore } from '@src/redux/store'
import { getDefaultValuesForDevice } from '@src/utils/platform-utils'
import { PageProps } from '@src/types'
import { eventTrackingClient, userClient } from '@common/clients'
import { useScript } from '@src/hooks/dom'
import firebaseConfig, { performanceStandaloneUrl } from '@src/config/firebase'
import customEventCallback from '../hooks/custom-event'

import UserSettingsConfigProvider from '@src/provider/user-settings-config'

const initializeFirebase = () => {
    if (window.firebase && firebaseConfig.apiKey) {
        // Initialize firebase with Firebase App config.
        window.firebase.initializeApp(firebaseConfig).performance()
    }
}

interface OwnProps {
    // Workaround for https://github.com/zeit/next.js/issues/8592.
    err?: any
}

interface Props extends OwnProps, AppProps {
    pageProps: PageProps
}

const App = ({ Component, pageProps, err }: Props) => {
    const router = useRouter()
    const [isClient, setIsClient] = useState(false)
    const { initialReduxState } = pageProps

    useEffect(() => {
        polyfill()
        setIsClient(!isServer)
    }, [])

    useEffect(() => {
        window.addEventListener('customActionEvent', customEventCallback)
        return () => {
            window.removeEventListener('customActionEvent', customEventCallback)
        }
    }, [])

    useEffect(() => {
        const hasAnyUTMKey = UTMKeys.some(key => key in router.query)

        if (hasAnyUTMKey) {
            const UTMTrackingEvent = new CustomEvent('customActionEvent', {
                detail: { type: homePageEvents.utmEvent, action: router.query },
                bubbles: true,
                cancelable: true,
                composed: false,
            })

            window.dispatchEvent(UTMTrackingEvent)
        }
    }, [])

    const { device, requestUserConfig, messages } = pageProps

    useEffect(() => {
        ;(async () => {
            await Promise.all([eventTrackingClient.setup(requestUserConfig), userClient.setup(requestUserConfig)])
        })()
    }, [requestUserConfig])

    const store = useStore(initialReduxState)

    useScript(performanceStandaloneUrl, { defer: false }, initializeFirebase)
    useScript('https://accounts.google.com/gsi/client', { defer: false })

    return (
        <Provider store={store}>
            <ScreenProvider queries={queries} values={isClient ? undefined : getDefaultValuesForDevice(device)}>
                <ThemeProvider theme={theme}>
                    <UserSettingsConfigProvider requestUserConfig={requestUserConfig} messages={messages}>
                        <style jsx global>
                            {GlobalFontFaces}
                        </style>
                        <GlobalStyles />
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        <Component {...pageProps} err={err} />
                    </UserSettingsConfigProvider>
                </ThemeProvider>
            </ScreenProvider>
        </Provider>
    )
}

export default App
