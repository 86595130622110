import { global } from 'styled-jsx/css'

import config from '@common/config'

const { basePath } = config

export const GlobalFontFaces = global`
    // Lato fonts.
    /* latin-ext */
    @font-face {
        font-family: 'Lato';
        font-style: italic;
        font-weight: 100;
        font-display: swap;
        src: local('Lato Hairline Italic'), local('Lato-HairlineItalic'),
            url(https://fonts.gstatic.com/s/lato/v16/S6u-w4BMUTPHjxsIPx-mPCLC79U11vU.woff2) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
            U+A720-A7FF;
    }
    /* latin */
    @font-face {
        font-family: 'Lato';
        font-style: italic;
        font-weight: 100;
        font-display: swap;
        src: local('Lato Hairline Italic'), local('Lato-HairlineItalic'),
            url(https://fonts.gstatic.com/s/lato/v16/S6u-w4BMUTPHjxsIPx-oPCLC79U1.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
            U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* latin-ext */
    @font-face {
        font-family: 'Lato';
        font-style: italic;
        font-weight: 300;
        font-display: swap;
        src: local('Lato Light Italic'), local('Lato-LightItalic'),
            url(https://fonts.gstatic.com/s/lato/v16/S6u_w4BMUTPHjxsI9w2_FQftx9897sxZ.woff2) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
            U+A720-A7FF;
    }
    /* latin */
    @font-face {
        font-family: 'Lato';
        font-style: italic;
        font-weight: 300;
        font-display: swap;
        src: local('Lato Light Italic'), local('Lato-LightItalic'),
            url(https://fonts.gstatic.com/s/lato/v16/S6u_w4BMUTPHjxsI9w2_Gwftx9897g.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
            U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* latin-ext */
    @font-face {
        font-family: 'Lato';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: local('Lato Italic'), local('Lato-Italic'),
            url(https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAUi-qNiXg7eU0.woff2) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
            U+A720-A7FF;
    }
    /* latin */
    @font-face {
        font-family: 'Lato';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: local('Lato Italic'), local('Lato-Italic'),
            url(https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-qNiXg7Q.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
            U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* latin-ext */
    @font-face {
        font-family: 'Lato';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: local('Lato Bold Italic'), local('Lato-BoldItalic'),
            url(https://fonts.gstatic.com/s/lato/v16/S6u_w4BMUTPHjxsI5wq_FQftx9897sxZ.woff2) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
            U+A720-A7FF;
    }
    /* latin */
    @font-face {
        font-family: 'Lato';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: local('Lato Bold Italic'), local('Lato-BoldItalic'),
            url(https://fonts.gstatic.com/s/lato/v16/S6u_w4BMUTPHjxsI5wq_Gwftx9897g.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
            U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* latin-ext */
    @font-face {
        font-family: 'Lato';
        font-style: italic;
        font-weight: 900;
        font-display: swap;
        src: local('Lato Black Italic'), local('Lato-BlackItalic'),
            url(https://fonts.gstatic.com/s/lato/v16/S6u_w4BMUTPHjxsI3wi_FQftx9897sxZ.woff2) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
            U+A720-A7FF;
    }
    /* latin */
    @font-face {
        font-family: 'Lato';
        font-style: italic;
        font-weight: 900;
        font-display: swap;
        src: local('Lato Black Italic'), local('Lato-BlackItalic'),
            url(https://fonts.gstatic.com/s/lato/v16/S6u_w4BMUTPHjxsI3wi_Gwftx9897g.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
            U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* latin-ext */
    @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 100;
        font-display: swap;
        src: local('Lato Hairline'), local('Lato-Hairline'),
            url(https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHh30AUi-qNiXg7eU0.woff2) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
            U+A720-A7FF;
    }
    /* latin */
    @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 100;
        font-display: swap;
        src: local('Lato Hairline'), local('Lato-Hairline'),
            url(https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHh30AXC-qNiXg7Q.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
            U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* latin-ext */
    @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: local('Lato Light'), local('Lato-Light'),
            url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh7USSwaPGQ3q5d0N7w.woff2) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
            U+A720-A7FF;
    }
    /* latin */
    @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: local('Lato Light'), local('Lato-Light'),
            url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh7USSwiPGQ3q5d0.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
            U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* latin-ext */
    @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: local('Lato Regular'), local('Lato-Regular'),
            url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
            U+A720-A7FF;
    }
    /* latin */
    @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: local('Lato Regular'), local('Lato-Regular'),
            url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
            U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* latin-ext */
    @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: local('Lato Bold'), local('Lato-Bold'),
            url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwaPGQ3q5d0N7w.woff2) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
            U+A720-A7FF;
    }
    /* latin */
    @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: local('Lato Bold'), local('Lato-Bold'),
            url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
            U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* latin-ext */
    @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 900;
        font-display: swap;
        src: local('Lato Black'), local('Lato-Black'),
            url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh50XSwaPGQ3q5d0N7w.woff2) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
            U+A720-A7FF;
    }
    /* latin */
    @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 900;
        font-display: swap;
        src: local('Lato Black'), local('Lato-Black'),
            url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh50XSwiPGQ3q5d0.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
            U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    // Yummo fonts.
    @font-face {
        font-family: 'Yummo';
        src: local('Yummo Bold'), local('Yummo-Bold'), url('${basePath}/fonts/Yummo-Bold.otf') format('opentype');
        font-weight: bold;
        font-display: swap;
    }
    @font-face {
        font-family: 'Yummo';
        src: local('Yummo Bold'), local('Yummo-Bold'), url('${basePath}/fonts/Yummo-Bold.otf') format('opentype');
        font-weight: 700;
        font-display: swap;
    }

    @font-face {
        font-family: 'Yummo';
        src: local('Yummo SemiBold'), local('Yummo-SemiBold'),
            url('${basePath}/fonts/Yummo-SemiBold.otf') format('opentype');
        font-weight: 600;
        font-display: swap;
    }

    @font-face {
        font-family: 'Yummo';
        src: local('Yummo Regular'), local('Yummo-Regular'),
            url('${basePath}/fonts/Yummo-Regular.otf') format('opentype');
        font-weight: normal;
        font-display: swap;
    }
    @font-face {
        font-family: 'Yummo';
        src: local('Yummo Regular'), local('Yummo-Regular'),
            url('${basePath}/fonts/Yummo-Regular.otf') format('opentype');
        font-weight: 400;
        font-display: swap;
    }

    @font-face {
        font-family: 'Yummo';
        src: local('Yummo Light'), local('Yummo-Light'),
            url('${basePath}/fonts/Yummo-Light.otf') format('opentype');
        font-weight: 200;
        font-display: swap;
    }

    @font-face {
        font-family: 'Yummo';
        src: local('Yummo Thin'), local('Yummo-Thin'), url('${basePath}/fonts/Yummo-Thin.otf') format('opentype');
        font-weight: 100;
        font-display: swap;
    }

    // Latin fonts.
    @font-face {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2)
          format("woff2");
      }
      @font-face {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2)
          format("woff2");
      }
      @font-face {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2)
          format("woff2");
      }
      @font-face {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/inter/v2/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2)
          format("woff2");
      }
`
