export const homePageEventLabels = {
    headerSection: {
        NAVIGATION_MENU: 'hamburger',
    },
    testimonialsSection: {
        CHANGE_CAROUSEL_SLIDE: 'browse testimonials',
    },
    howItWorksSection: {
        CHANGE_CAROUSEL_SLIDE: 'browse registration steps',
    },
    moneyBackGuaranteeSection: {
        SHOW_MORE: 'tnc',
    },
    faqSection: {
        SHOW_MORE: 'accordion',
        SHOW_MORE_ALL: 'show all faqs',
    },
    yoloLogin: {
        PAGE: 'vermieten_page',
    },
    premiumPartnershipSection: {
        BUTTON: 'premium partnership',
    },
    mlwSection: {
        BUTTON: 'multilingual website',
    },
    howItWorksV2Section: {
        BUTTON: 'how it works',
    },
    customerSupportSection: {
        BUTTON: 'customer support',
    },
    StickyButtons: {
        CALL_US: 'sticky call us',
        REGISTER_NOW: 'sticky register now',
    },
    offerSection: {
        VERMIETEN_HAHALLOWEEN_OFFER: 'vermieten_halloween_offer',
    },
}

export const registrationFormEventLabels = {
    fieldError: {
        ALL_FIELDS_EMPTY: 'allfields',
        SALUTATION_EMPTY: 'e_salutation',
        FIRST_NAME_EMPTY: 'e_firstname',
        FIRST_NAME_INVALID: 'i_firstname',
        LAST_NAME_EMPTY: 'e_lastname',
        LAST_NAME_INVALID: 'i_lastname',
        EMAIL_EMPTY: 'e_email',
        EMAIL_EXISTING_HOMEOWNER: 'i_hoemail',
        EMAIL_EXISTING_GUEST: 'i_guemail',
        EMAIL_UNDER_DELETION: 'i_delemail',
        PHONE_NUMBER_EMPTY: 'e_number',
        PHONE_NUMBER_INVALID: 'i_number',
        COMPANY_AGENCY_PROPERTIES_EMPTY: 'e_question',
        COMPANY_AGENCY_LOCATION_EMPTY: 'e_location',
    },
    formError: {
        RATE_LIMIT_EXCEEDED: 'ratelimit',
        TEMPORARY_AUTHENTICATION_ERROR: 'tempauth',
        INTERNAL_SERVER_ERROR: 'intserver',
        SOME_SERVER_ERROR: 'someserverror',
    },
    clickCompleteRegistration: 'click_complete_registration',
    field: {
        SALUTATION: 'salutation',
        FIRST_NAME: 'firstname',
        LAST_NAME: 'lastname',
        EMAIL: 'email',
        PHONE: 'phone',
    },
}
