import Cookies, { CookieAttributes } from 'js-cookie'
import { Request, Response } from 'express'

import { isServer } from '@common/utils/platform-utils'
import config from '@common/config'

class CookieManagerService {
    public static readonly DEVICE_SESSION_ID = 'heracles_oauth_device_verifier'
    public static readonly PREFERRED_LOCALE = 'preferred_locale'

    public static setCookie(key: string, value: string | object, options?: CookieAttributes): void {
        if (!isServer) {
            Cookies.set(key, value, options)
        }
    }

    public static getCookie(key: string, req?: Request): string | undefined {
        if (isServer) {
            return req?.cookies[key]
        }
        return Cookies.get(key)
    }

    public static deleteCookie(key: string, options?: CookieAttributes, res?: Response): Response | void {
        if (isServer) {
            return res?.clearCookie(key, options)
        }
        return Cookies.remove(key, options)
    }

    public static getCookieDomain() {
        if (config.environment === 'staging') {
            return '.svc.staging.tfw.io'
        }
    }
}

export default CookieManagerService
