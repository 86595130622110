import config from '@common/config'
import { Language, CountryCode } from '@common/types'
import { isServer } from '@common/utils/platform-utils'

const _getHostnameLanguageMap = (environment: string) => {
    switch (environment) {
        case 'production':
            return new Map<string, Language>([
                ['traum-ferienwohnungen.de', 'de_DE'],
                ['traum-ferienwohnungen.ch', 'de_CH'],
                ['traum-ferienwohnungen.at', 'de_AT'],
                ['vacation-apartments.com', 'en_US'],
                ['holiday-apartments.co.uk', 'en_GB'],
                ['traumhomes.nl', 'nl_NL'],
                ['traumhomes.fr', 'fr_FR'],
                ['traumhomes.it', 'it_IT'],
                ['traumhomes.es', 'es_ES'],
            ])
        case 'staging':
            return new Map<string, Language>([['homeowner-portal.svc.staging.tfw.io', 'de_DE']])
        default:
            return new Map<string, Language>([['localhost', 'de_DE']])
    }
}

const _hostnameLanguageMap = _getHostnameLanguageMap(config.environment)

const _getDomainPerEnvironment = (environment: string) => {
    switch (environment) {
        case 'production':
            return (hostname?: string | null): string => {
                const currentHostname = hostname ?? (!isServer ? location.hostname : 'traum-ferienwohnungen.de')
                const match = currentHostname.match(
                    // eslint-disable-next-line max-len
                    /(www\.)?(traum-ferienwohnungen|vacation-apartments|holiday-apartments|traumhomes)(\.de|\.ch|\.at|\.co\.uk|\.com|\.nl|\.fr|\.it|\.es)/,
                )

                return match ? match[match.length - 2] + match[match.length - 1] : 'traum-ferienwohnungen.de'
            }
        case 'staging':
            return (hostname?: string | null): string => {
                const currentHostname =
                    hostname ?? (!isServer ? location.hostname : 'homeowner-portal.svc.staging.tfw.io')

                const match = currentHostname.match(/(homeowner-portal)(\.svc\.staging\.tfw\.io)/)
                return match ? match[match.length - 2] + match[match.length - 1] : 'homeowner-portal.svc.staging.tfw.io'
            }
        default:
            return (): string => {
                return 'localhost'
            }
    }
}

export const getDomain = _getDomainPerEnvironment(config.environment)

export const getLanguage = (hostname?: string | null): Language => {
    const domain = getDomain(hostname)
    return _hostnameLanguageMap.get(domain) ?? 'de_DE'
}

export const getCountryCode = (hostname?: string | null, language?: string | null): CountryCode => {
    const _language = language ?? getLanguage(hostname)
    const country = _language.split('_')[1] || 'DE'
    return country as CountryCode
}

const _getLanguageHostnameMap = () => {
    const languageHostnameMap = new Map<Language, string>()
    _hostnameLanguageMap.forEach((language, hostname) => {
        languageHostnameMap.set(language, hostname)
    })
    return languageHostnameMap
}

const _languageHostnameMap = _getLanguageHostnameMap()

export const getDomainFromLanguage = (language: Language): string =>
    _languageHostnameMap.get(language) ?? _languageHostnameMap.get('de_DE') ?? 'traum-ferienwohnungen.de'

const _languages = new Set(['de_AT', 'de_CH', 'de_DE', 'en_GB', 'en_US', 'nl_NL', 'fr_FR', 'it_IT', 'es_ES'])

export const isLanguage = (language: string) => _languages.has(language)
