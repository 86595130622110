import { createReducer } from 'redux-act'

import State from './types'
import {
    startCheckDeviceSessionTransport,
    saveCheckDeviceSessionTransport,
    errorCheckDeviceSessionTransport,
} from './actions'
import initialState from './state'
import BaseTransport from '@common/transports/common/base-transport'
import CheckDeviceSessionTransport from '@common/transports/check-device-session-transport'

const _startCheckDeviceSessionTransport = (state: State): State => ({
    ...state,
    checkDeviceSession: {
        ...state.checkDeviceSession,
        isLoading: true,
        error: null,
    },
})

const _saveCheckDeviceSessionTransport = (
    state: State,
    payload: BaseTransport<CheckDeviceSessionTransport>,
): State => ({
    ...state,
    checkDeviceSession: {
        ...state.checkDeviceSession,
        isLoading: false,
        transport: payload,
    },
})

const _errorCheckDeviceSessionTransport = (state: State, payload: Error): State => ({
    ...state,
    checkDeviceSession: {
        ...state.checkDeviceSession,
        isLoading: false,
        error: payload,
    },
})

const reducer = createReducer<State>(on => {
    on(startCheckDeviceSessionTransport, _startCheckDeviceSessionTransport)
    on(saveCheckDeviceSessionTransport, _saveCheckDeviceSessionTransport)
    on(errorCheckDeviceSessionTransport, _errorCheckDeviceSessionTransport)
}, initialState)

export default reducer
