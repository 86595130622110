export const basicEventActions = {
    CLICK: 'click',
    MOUSEOVER: 'mouseover',
    SWIPE: 'swipe',
    YOLO_LOGIN_VIEW: 'vermieten_yolo_view',
    YOLO_LOGIN_CLICK: 'vermieten_yolo_click',
    YOLO_LOGIN_SUCCESS_VIEW: 'vermieten_post_yolo_view',
    VERMIETEN_VIEW: 'vermieten_view',
}

export const registrationFormEventActions = {
    ON_BLUR: 'ho_registration_onblur',
    ON_SUBMIT: 'ho_registration_onsubmit',
    ON_DATA_VIEW: 'ho_registration_form_data_view',
    ON_DATA_FILLED: 'ho_registration_form_data_filled',
}
