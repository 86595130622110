import { isServer } from '@common/utils/platform-utils'
import { basicEventActions } from '@src/services/analytics-service/actions'

class AnalyticsService {
    public static trackEvent(
        eventAction: string,
        eventCategory: string,
        eventLabel: string,
        eventValue?: number | string,
    ) {
        if (!isServer && window.dataLayer?.push) {
            const event = {
                // must be auto trigger to forward event to correct GA tracking ID
                event: 'autoTrigger',
                ga_eventAction: eventAction.toLowerCase(),
                ga_eventCategory: eventCategory.toLowerCase(),
                ga_eventLabel: eventLabel.toLowerCase(),
            }

            if (eventValue !== undefined) {
                try {
                    eventValue = Number.parseInt(eventValue as string)

                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore-next-line
                    event.ga_eventValue = eventValue
                } catch {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    event.ga_eventValue = undefined
                }
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                event.ga_eventValue = undefined
            }

            window.dataLayer?.push?.(event)
        }
    }

    public static trackClickEvent(eventCategory: string, eventLabel: string) {
        AnalyticsService.trackEvent(basicEventActions.CLICK, eventCategory, eventLabel)
    }

    public static trackMouseoverEvent(eventCategory: string, eventLabel: string) {
        AnalyticsService.trackEvent(basicEventActions.MOUSEOVER, eventCategory, eventLabel)
    }

    public static trackSwipeEvent(eventCategory: string, eventLabel: string) {
        AnalyticsService.trackEvent(basicEventActions.SWIPE, eventCategory, eventLabel)
    }

    public static trackYoloEvent(eventAction: string, eventCategory: string, eventLabel: string) {
        AnalyticsService.trackEvent(eventAction, eventCategory, eventLabel)
    }
}

export default AnalyticsService
