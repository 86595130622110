import { AnalyticsService } from '@src/services'
import { basicEventActions } from '@src/services/analytics-service/actions'
import { homePageEventCategories } from '@src/services/analytics-service/categories'
import { homePageEventLabels } from '@src/services/analytics-service/labels'
import { UTMKeys } from '@server/constants'

export default function customEventCallback(event: any) {
    if (event && event.detail) {
        if (event.detail.type === 'social_login') {
            switch (event.detail.action) {
                case 'display':
                    AnalyticsService.trackYoloEvent(
                        basicEventActions.YOLO_LOGIN_VIEW,
                        homePageEventCategories.YOLO_LOGIN,
                        homePageEventLabels.yoloLogin.PAGE,
                    )
                    break
                case 'click':
                    AnalyticsService.trackYoloEvent(
                        basicEventActions.YOLO_LOGIN_CLICK,
                        homePageEventCategories.YOLO_LOGIN,
                        homePageEventLabels.yoloLogin.PAGE,
                    )
                    break
                case 'success':
                    AnalyticsService.trackYoloEvent(
                        basicEventActions.YOLO_LOGIN_SUCCESS_VIEW,
                        homePageEventCategories.YOLO_LOGIN,
                        homePageEventLabels.yoloLogin.PAGE,
                    )
                    break
            }
        } else if (event.detail.type === 'utm_tracking_event') {
            const actionParams = event.detail.action
            const concatenatedValue: string = UTMKeys.filter(key => key in actionParams)
                .map(key => key + '=' + actionParams[key])
                .join('&')

            AnalyticsService.trackEvent(
                basicEventActions.VERMIETEN_VIEW,
                homePageEventCategories.VERMIETEN_PAGE,
                concatenatedValue,
            )
        }
    }
}
