import { NetworkService } from '@common/services'
import { Headers } from '@common/services/network-service/types'
import { RequestUserConfig } from '@common/types'
import { isServer } from '@common/utils/platform-utils'
import config from '@common/config'
import TrackUserEntranceTransport from '@common/transports/track-user-entrance-transport'

class EventTrackingClient {
    private readonly _networkService: NetworkService
    private readonly _basePath: string

    constructor(baseUri: string, basePath: string) {
        this._networkService = new NetworkService(baseUri)
        this._basePath = basePath
    }

    public async setup(requestUserConfig?: RequestUserConfig) {
        if (requestUserConfig) {
            const headers: Headers = {
                'x-language': requestUserConfig.language,
                'x-domain': requestUserConfig.domain,
            }

            this._networkService.setDefaultHeaders(headers)
        }
    }

    public trackUserEntrance({ body }: { body: TrackUserEntranceTransport }): Promise<void> {
        return this._networkService.post({
            body,
            url: `${this._basePath}/user-entrance`,
            shouldHandleNetworkAndServerError: false,
            restConfig: { withCredentials: true },
        })
    }
}

const baseUri = isServer ? (config.bffBaseUrl as string) : location.origin + config.basePath

export default new EventTrackingClient(baseUri, '/api/v1/event-tracking')
