import axios from 'axios'

const setupAxios = () => {
    // Use the actual check here as webpack does not replace `isServer` but it replaces `typeof window` if it is used
    // directly.
    if (typeof window === 'undefined') {
        // Only log the request, response & errors on server.
        // Use `.then` syntax because `async/await` does not work with server-code elimination on Next.js
        import('@server/utils/axios-interceptors')
            .then(({ logRequest, logRequestError, logResponse, logResponseError }) => {
                axios.interceptors.request.use(logRequest, logRequestError)
                axios.interceptors.response.use(logResponse, logResponseError)
            })
            .catch(error => {
                // Do not use custom logger for this as the the error could have occurred because of wrong dynamic
                // import.
                console.error(error)
            })
    }
}

export default setupAxios
