import { MouseEventHandler, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { isServer } from '@common/utils/platform-utils'
import { isFunction } from '@common/utils/type-utils'
import { CallbackFunction } from '@common/types'

export const useIntersectionObserver = (
    callback: IntersectionObserverCallback,
    options?: IntersectionObserverInit,
    deps: any[] = [],
) =>
    useMemo(
        () => (!isServer && isFunction(IntersectionObserver) ? new IntersectionObserver(callback, options) : null),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps,
    )

export const useIntersectionObserverEffect = <T extends HTMLElement = HTMLElement>(
    callback: IntersectionObserverCallback,
    options?: IntersectionObserverInit,
) => {
    const observer = useIntersectionObserver(callback, options)
    const ref = useRef<T>(null)

    useEffect(() => {
        const target = ref.current!
        if (target) {
            observer?.observe(target)
            return () => {
                observer?.unobserve(target)
            }
        }
    }, [observer])

    return [ref]
}

export const useHover = <T extends HTMLElement = HTMLElement>(
    fallbackToClickFunctionality?: boolean,
): [RefObject<T>, boolean] => {
    const [isBeingHovered, setIsBeingHovered] = useState(false)
    const ref = useRef<T>(null)

    const _handleMouseEnter = useCallback(() => setIsBeingHovered(true), [])
    const _handleMouseLeave = useCallback(() => setIsBeingHovered(false), [])
    const _handleClick = useCallback(() => setIsBeingHovered(isBeingHovered => !isBeingHovered), [])

    useEffect(() => {
        const node = ref.current
        if (node) {
            node.addEventListener('mouseenter', _handleMouseEnter)
            node.addEventListener('mouseleave', _handleMouseLeave)
            if (fallbackToClickFunctionality) {
                node.addEventListener('click', _handleClick)
            }

            return () => {
                node.removeEventListener('mouseenter', _handleMouseEnter)
                node.removeEventListener('mouseleave', _handleMouseLeave)
                if (fallbackToClickFunctionality) {
                    node.removeEventListener('click', _handleClick)
                }
            }
        }
    }, [_handleMouseEnter, _handleMouseLeave, _handleClick, fallbackToClickFunctionality])

    return [ref, isBeingHovered]
}

const _loadScript = (url: string, { defer = false }: { defer?: boolean } = {}, onLoad?: CallbackFunction) => {
    let script: HTMLScriptElement | null = document.querySelector(`script[src="${url}"]`)

    if (!script) {
        script = document.createElement('script')
        script.src = url
        script.type = 'text/javascript'
        script[defer ? 'defer' : 'async'] = true
        document.head.append(script)

        script.addEventListener('error', () => {
            if (script) {
                script.dataset.failed = 'true'
            }
        })
        script.addEventListener('load', () => {
            if (script) {
                script.dataset.loaded = 'true'
            }
        })
    }

    // Already loaded, so we can return early.
    if (script?.dataset.loaded === 'true') {
        onLoad?.()
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {}
    }

    if (!isFunction(onLoad)) {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {}
    }

    // Add load event listener.
    script.addEventListener('load', onLoad)

    return () => {
        if (script) {
            script.removeEventListener('load', onLoad)
        }
    }
}

/**
 *  Hook to load an external script. Returns true once the script has finished loading.
 */
export const useScript = (url: string, { defer = false }: { defer?: boolean } = {}, onLoad?: CallbackFunction) => {
    const [ready, setReady] = useState(false)

    const callback = useCallback(() => {
        if (isFunction(onLoad)) {
            onLoad()
        }
        setReady(true)
    }, [onLoad])

    useEffect(() => _loadScript(url, { defer }, callback), [callback, url, defer])

    return [ready]
}

export const useOutsideClick = <T extends HTMLElement = HTMLElement>(callback: MouseEventHandler<T>) => {
    const ref = useRef<T>(null)
    const _handleClick = useCallback(
        event => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback(event)
            }
        },
        [callback],
    )

    useEffect(() => {
        document.addEventListener('click', _handleClick)

        return () => {
            document.removeEventListener('click', _handleClick)
        }
    }, [_handleClick])

    return [ref]
}

export const useEffectExceptOnMount = (callback: CallbackFunction, deps: any[]) => {
    const isMounted = useRef(false)

    useEffect(() => {
        if (isMounted.current) {
            callback()
        }
        isMounted.current = true
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, deps)
}
