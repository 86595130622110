import axios from 'axios'

import {
    BodilessRequestConfig,
    DefaultRequestConfig,
    GetRequestConfig,
    Headers,
    Params,
    PostRequestConfig,
    PutRequestConfig,
} from './types'
import setupAxios from './setup-axios'
import { isFunction } from '@common/utils/type-utils'

class NetworkService {
    private readonly _baseUri: string
    private _defaults: DefaultRequestConfig = { headers: {}, params: {} }

    constructor(baseUri: string) {
        this._baseUri = baseUri
    }

    public static async init() {
        await setupAxios()
    }

    public setDefaultHeaders(headers: Headers = {}) {
        this._defaults.headers = headers
    }

    public setDefaultParams(params: Params = {}) {
        this._defaults.params = params
    }

    public async get({
        headers,
        url,
        params,
        restConfig = {},
        successCallback,
        errorCallback,
    }: GetRequestConfig): Promise<any> {
        const config = this._getRequestConfig({ headers, params, restConfig })
        const computedUrl = this._baseUri + url
        try {
            const response = await axios.get(computedUrl, config)
            if (isFunction(successCallback)) {
                return successCallback(response.data)
            }
            return response.data
        } catch (error) {
            const err = (error.response && error.response.data) || error
            if (isFunction(errorCallback)) {
                return errorCallback(err)
            }
            throw err
        }
    }

    public async post({
        headers,
        url,
        params,
        body,
        restConfig = {},
        successCallback,
        errorCallback,
    }: PostRequestConfig): Promise<any> {
        const config = this._getRequestConfig({ headers, params, restConfig })
        const computedUrl = this._baseUri + url
        try {
            const response = await axios.post(computedUrl, body, config)
            if (isFunction(successCallback)) {
                return successCallback(response.data)
            }
            return response.data
        } catch (error) {
            const err = (error.response && error.response.data) || error
            if (isFunction(errorCallback)) {
                return errorCallback(err)
            }
            throw err
        }
    }

    public async put({
        headers,
        url,
        params,
        body,
        restConfig = {},
        successCallback,
        errorCallback,
    }: PutRequestConfig): Promise<any> {
        const config = this._getRequestConfig({ headers, params, restConfig })
        const computedUrl = this._baseUri + url
        try {
            const response = await axios.put(computedUrl, body, config)
            if (isFunction(successCallback)) {
                return successCallback(response.data)
            }
            return response.data
        } catch (error) {
            const err = (error.response && error.response.data) || error
            if (isFunction(errorCallback)) {
                return errorCallback(err)
            }
            throw err
        }
    }

    private _getRequestConfig({
        headers = {},
        params = {},
        restConfig = {},
    }: Pick<BodilessRequestConfig, 'headers' | 'params' | 'restConfig'>) {
        return {
            ...restConfig,
            headers: { ...this._defaults.headers, ...headers },
            params: { ...this._defaults.params, ...params },
        }
    }
}

export default NetworkService
