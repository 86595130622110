import State from './types'

const initialState: State = {
    checkDeviceSession: {
        isLoading: false,
        error: null,
        transport: null,
    },
}

export default initialState
