import { useMemo } from 'react'
import { applyMiddleware, CombinedState, compose, createStore, Store } from 'redux'
import thunkMiddleware from 'redux-thunk'
import logger from 'redux-logger'

import rootReducer from './reducer'
import config from '@common/config'
import RootState from '@src/redux/types'
import { isServer } from '@common/utils/platform-utils'

const middleWares = [thunkMiddleware]
if (config.environment !== 'production') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    middleWares.push(logger)
}
const middleWareEnhancer = applyMiddleware(...middleWares)

/* eslint-disable no-underscore-dangle */
const composeEnhancers =
    typeof (window as any) === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose
/* eslint-enable */

const initStore = (preloadedState?: RootState) =>
    createStore(rootReducer, preloadedState, composeEnhancers(middleWareEnhancer))

let store: Store<CombinedState<RootState>> | undefined

export const initializeStore = (preloadedState?: RootState) => {
    let _store = store ?? initStore(preloadedState)

    // After navigating to a page with an initial Redux state, merge that state with the current state in the store,
    // and create a new store.
    if (preloadedState && store) {
        _store = initStore({
            ...store.getState(),
            ...preloadedState,
        })
        // Reset the current store.
        store = undefined
    }

    // For SSG and SSR always create a new store.
    if (isServer) {
        return _store
    }

    // Create the store once in the client.
    if (!store) {
        store = _store
    }

    return _store
}

export const useStore = (initialState?: RootState) => {
    const store = useMemo(() => initializeStore(initialState), [initialState])
    return store
}

export const getStore = () => store
